










































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IFemaleReproductiveHealth, IPriorPregnancies } from '@/interfaces/patients';
import {
  menstrualStatusListOptions,
  menstrualStatusOptions,
  timeOnBirthControlOptionFallback,
  timeOnBirthControlOptions,
  timeOnBirthControlOptionsSelect,
} from '@/constants/listOptions';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientFemaleHealthInfo extends AppComponent {
  @Prop({ required: false }) public femaleHealthInfo: IFemaleReproductiveHealth;
  @Prop({ required: false }) public priorPregnanciesProfile: IPriorPregnancies;

  public valid = false;

  public currentlyOnBirthControl: boolean = false;
  public birthControlType: string[] = [];
  public dateOfLastPeriod: string = '';
  public priorPregnancies: boolean = false;
  public currentlyPregnant: boolean = false;
  public tryingToConceive: boolean = false;
  public priorPregnanciesCount: number | null = null;
  public numberOfChildren: number | null = null;
  public numberOfMiscarriages: number | null = null;
  public numberOfAbortions: number | null = null;
  public currentMenstrualStatus: string  = '';
  public menstrualStatusListOptions = menstrualStatusListOptions;

  public timeOnBirthControl: string = '';
  public displayOtherTimeOnBirthControl: boolean = false;
  public timeOnBirthControlOption: string = '';
  public birthControlTimesOptions = timeOnBirthControlOptionsSelect;

  public assignedMenstrualStatus: string = '';

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  public setDateOfLastPeriod(newDate) {
      this.dateOfLastPeriod = newDate;
  }

  @Watch('femaleHealthInfo')
  public onContactInfoChanged(newValue: IFemaleReproductiveHealth) {
    this.reset();
  }

  /**
   * Watcher for prior pregnancies flag
   * If this flag changes to false, we will reset the prior pregnancies fields
   * nextTick to avoid errors with ve-validate form
   */
  @Watch('priorPregnancies')
  public onPriorPregnanciesChanged(newValue: boolean) {
    if (!newValue) {
      this.$nextTick(() => {
        this.priorPregnanciesCount = null;
        this.numberOfChildren = null;
        this.numberOfMiscarriages = null;
        this.numberOfAbortions = null;
      });
    }
  }

  /**
   * watcher for currently on birth control flag
   * If this flag changes to false, we need to reset
   * the birth control type and time on birth control fields
   * using nextTick to avoid errors with ve-validate form
   */
  @Watch('currentlyOnBirthControl')
  public onCurrentlyOnBirthControlChanged(newValue: boolean) {
    if (!newValue) {
      this.$nextTick(() => {
        this.birthControlType = [];
        this.timeOnBirthControl = '';
    });
    }
  }

  /**
   * Watches for changes in time on birth control
   * if the value matches with a value from the available options
   * this value is set. otherwise the "others" option is set
   * and an extra field is displayed to allow the user to enter the custom value
   */
  @Watch('timeOnBirthControl')
  public timeOnBirthControlChanged(newValue: string) {
    if (newValue) {
      this.$nextTick(() => {
        const tempComparingList = timeOnBirthControlOptions.map((item: string) => item.toLowerCase());
        const optionIndex = tempComparingList.indexOf(newValue.toLowerCase());
        if (optionIndex > -1) {
          this.timeOnBirthControlOption = timeOnBirthControlOptions[optionIndex];
          this.displayOtherTimeOnBirthControl = false;
        } else {
          this.timeOnBirthControlOption = timeOnBirthControlOptionFallback;
          this.displayOtherTimeOnBirthControl = true;
        }
      });
    }
  }

  @Watch('timeOnBirthControlOption')
  public timeOnBirthControlOptionChanged(newValue: string) {
    if (newValue) {
      this.$nextTick(() => {
        if (newValue === timeOnBirthControlOptionFallback) {
          this.displayOtherTimeOnBirthControl = true;
        } else {
          this.displayOtherTimeOnBirthControl = false;
          this.timeOnBirthControl = newValue;
        }
      });
    }
  }

  public reset() {
      if (this.femaleHealthInfo && this.femaleHealthInfo.id) {
        this.currentlyOnBirthControl = this.femaleHealthInfo.currentlyOnBirthControl || false;
        this.birthControlType = this.femaleHealthInfo.birthControlType
        ? this.cloneList(this.femaleHealthInfo.birthControlType as string []) : [];
        this.timeOnBirthControl = this.femaleHealthInfo.timeOnBirthControl || '';
        this.dateOfLastPeriod = this.femaleHealthInfo.dateOfLastPeriod || '';
        this.currentlyPregnant = this.femaleHealthInfo.currentlyPregnant || false;
        this.tryingToConceive = this.femaleHealthInfo.tryingToConceive || false;
        this.currentMenstrualStatus = this.getAssignedMenstrualStatus(this.femaleHealthInfo.currentMenstrualStatus)
          || '';
        this.priorPregnancies = this.femaleHealthInfo.priorPregnancies || false;
        this.priorPregnanciesCount = this.priorPregnanciesProfile ? this.priorPregnanciesProfile.count || null : null;
        this.numberOfChildren = this.priorPregnanciesProfile ?
          this.priorPregnanciesProfile.numberOfChildren || null : null;
        this.numberOfMiscarriages = this.priorPregnanciesProfile
          ? this.priorPregnanciesProfile.numberOfMiscarriages || null : null;
        this.numberOfAbortions = this.priorPregnanciesProfile
          ? this.priorPregnanciesProfile.numberOfAbortions || null : null;


        this.$validator.reset();
      } else {
        this.currentlyOnBirthControl = false;
        this.birthControlType = [];
        this.timeOnBirthControl = '';
        this.dateOfLastPeriod = '';
        this.currentlyPregnant = false;
        this.tryingToConceive = false;
        this.currentMenstrualStatus = menstrualStatusOptions.premenopause.value;
        this.priorPregnancies = false;
        this.priorPregnanciesCount = null;
        this.numberOfChildren = null;
        this.numberOfMiscarriages = null;
        this.numberOfAbortions = null;

        this.$validator.reset();
      }
  }

  public cancel() {
    this.$router.back();
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const femaleHealth: IFemaleReproductiveHealth = {
            currentlyOnBirthControl: this.currentlyOnBirthControl,
            birthControlType: this.birthControlType,
            timeOnBirthControl: this.timeOnBirthControl,
            dateOfLastPeriod: this.dateOfLastPeriod,
            currentlyPregnant: this.currentlyPregnant,
            tryingToConceive: this.tryingToConceive,

            currentMenstrualStatus: this.currentMenstrualStatus,
            priorPregnancies: this.priorPregnancies,
        };
        const priorPregnancies: IPriorPregnancies = {
            count: this.priorPregnanciesCount || 0,
            numberOfChildren: this.numberOfChildren || 0,
            numberOfMiscarriages: this.numberOfMiscarriages || 0,
            numberOfAbortions: this.numberOfAbortions || 0,
        };
        this.$emit('patient-input::female-health', {femaleHealth, priorPregnancies});
    }
  }

  /**
   * Gets the assigned menstrual status
   * by the content of the value from the server
   * Since the ops can put wrong values, we will check if the value is one of the valid values for each option
   */
  public getAssignedMenstrualStatus(val) {
    if (!val || val.length === 0) {
      return '';
    }
    let menstrualStatus = null;
    for (const option of menstrualStatusListOptions) {
      const expandedOption = menstrualStatusOptions[option.value];
      if (expandedOption && Object.keys(expandedOption).length > 0) {
        for (const valid of expandedOption.validValues) {
          if (val.toLowerCase().includes(valid)) {
            menstrualStatus = option.value;
            break;
          }
        }
      }
      if (menstrualStatus) {
        break;
      }
    }
    return menstrualStatus || '';
  }

}
