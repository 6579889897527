



































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IPatientBehaviorInfo } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientCovidInfo extends AppComponent {
  @Prop({ required: false }) public behaviorInfo: IPatientBehaviorInfo;
  public valid = false;

  public diet: string[] = [];
  public physicallyActive: boolean = false;
  public smoker: boolean = false;
  public smokingFrequency: string = '';
  public alcohol: boolean = false;
  public alcoholFrequency: string = '';

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('behaviorInfo')
  public onContactInfoChanged(newValue: IPatientBehaviorInfo) {
    this.reset();
  }

  public reset() {
      if (this.behaviorInfo && this.behaviorInfo.id) {
        this.diet = this.behaviorInfo.diet ? [... this.behaviorInfo.diet] : [];
        this.physicallyActive = this.behaviorInfo.physicallyActive || false;
        this.smoker = this.behaviorInfo.smoker || false;
        this.smokingFrequency = this.behaviorInfo.smokingFrequency || '';
        this.alcohol = this.behaviorInfo.alcohol || false;
        this.alcoholFrequency = this.behaviorInfo.alcoholFrequency || '';

        this.$validator.reset();
      } else {
        this.diet = [];
        this.physicallyActive = false;
        this.smoker = false;
        this.smokingFrequency = '';
        this.alcohol = false;
        this.alcoholFrequency = '';
        this.$validator.reset();
      }
  }

  public cancel() {
    this.$router.back();
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: IPatientBehaviorInfo = {
            diet: this.diet,
            physicallyActive: this.physicallyActive,
            smoker: this.smoker,
            smokingFrequency: this.smokingFrequency,
            alcohol: this.alcohol,
            alcoholFrequency: this.alcoholFrequency,
        };
        this.$emit('patient-input::behavior', patient);
    }
  }

}
