




































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IMotivationalInfo } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientMotivationalInfo extends AppComponent {
  @Prop({ required: false }) public motivationalInfo: IMotivationalInfo;
  public valid = false;

  public howDidHear: string[] = [];
  public commentBeforeBooking: string = '';
  public porpouseForJoining: string[] = [];
public healthGoals: string = '';


  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('motivationalInfo')
  public onContactInfoChanged(newValue: IMotivationalInfo) {
    this.reset();
  }

  public reset() {
      if (this.motivationalInfo && this.motivationalInfo.id) {
        this.howDidHear = this.motivationalInfo.howDidTheyHearAboutFunction
        ? this.cloneList(this.motivationalInfo.howDidTheyHearAboutFunction as string []) : [];
        this.commentBeforeBooking = this.motivationalInfo.commentsBeforeBooking || '';
        this.porpouseForJoining = this.motivationalInfo.purposeForJoiningFunction
        ? this.cloneList(this.motivationalInfo.purposeForJoiningFunction as string []) : [];
        this.healthGoals = this.motivationalInfo.tenYearHealthGoals || '';

        this.$validator.reset();
      } else {
        this.howDidHear = [];
        this.commentBeforeBooking = '';
        this.porpouseForJoining = [];
        this.healthGoals = '';
        this.$validator.reset();
      }
  }

  public cancel() {
    this.$router.back();
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: IMotivationalInfo = {
            commentsBeforeBooking: this.commentBeforeBooking,
            howDidTheyHearAboutFunction: this.howDidHear,
            purposeForJoiningFunction: this.porpouseForJoining,
            tenYearHealthGoals: this.healthGoals,
        };
        this.$emit('patient-input::motivational', patient);
    }
  }

}
