
































































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IPatient } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientGeneralInfo extends AppComponent {
  @Prop({ required: false }) public patientProfile: IPatient;
  public valid = false;
  /*
    patient personal data
  */
  public firstName: string = '';
  public lastName: string = '';
  public preferedName: string = '';
  public patientIdentifier: string = '';
  public patientFirebaseId: string = '';
  public patientFirebaseIdEditing: string = '';

  public pronouns: string = '';
  public ethnicOrigin: string[] = [] as string[];
  public biologicalSex: string = '';
  public dob: string = '';

  public sexOptions: string[] = ['Male', 'Female'];

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('patientProfile')
  public onPatientProfileChanged() {
      this.reset();
  }

  public reset() {
    this.firstName = this.patientProfile ? this.patientProfile.fname || '' : '';
    this.lastName = this.patientProfile ? this.patientProfile.lname  || '' : '';
    this.patientIdentifier = this.patientProfile ? this.patientProfile.patientIdentifier || '' : '';
    this.preferedName = this.patientProfile ? this.patientProfile.preferredName || ''  : '';
    this.pronouns = this.patientProfile ? this.patientProfile.pronouns || ''  : '';
    this.ethnicOrigin = this.patientProfile && this.patientProfile.ethnicOrigin ?
      this.cloneList(this.patientProfile.ethnicOrigin as string [])  || [] : [];
    this.biologicalSex = this.patientProfile ? this.patientProfile.biologicalSex || '' : '';
    this.dob = this.patientProfile ? this.patientProfile.dob || ''  : '';
    this.patientFirebaseId = this.patientProfile ? this.patientProfile.patientFirebaseId || ''  : '';
    this.patientFirebaseIdEditing = this.patientFirebaseId ? '' + this.patientFirebaseId : ''; // make a copy
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public setDate(newDate) {
    this.dob = newDate;
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: IPatient = {
            fname: this.firstName,
            lname: this.lastName,
            patientIdentifier: this.patientIdentifier,
            preferredName: this.preferedName,
            patientFirebaseId: this.patientFirebaseIdEditing,
            pronouns: this.pronouns,
            ethnicOrigin: this.ethnicOrigin,
            biologicalSex: this.biologicalSex,
            dob: this.dob,
        };
        this.$emit('patient-input::general-info', patient);
    }
  }

}
