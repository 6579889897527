
































































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IPatientMedicalHx } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientMedicalHx extends AppComponent {
  @Prop({ required: false }) public medicalHx: IPatientMedicalHx;
  public valid = false;

  public hasCronicalConditions: boolean = false;
  public chronicConditions: string[] = [];
  public chronicConditionsDiagnosisDatas: string[] = [];
  public takingMedications: boolean = false;
  public currentMeds: string[] = [''];
  public hadSurgeries: boolean = false;
  public surgeries: string[] = [''];
  public beenHospitalized: boolean = false;
  public hospitalizations: string[] = [];
  public hadIssuesWithBloodDraw: boolean = false;
  public bloodDrawIssues: string[] = [];

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('medicalHx')
  public onContactInfoChanged(newValue: IPatientMedicalHx) {
    this.reset();
  }

  public reset() {
      if (this.medicalHx && this.medicalHx.id) {
        this.hasCronicalConditions = this.medicalHx.hasChronicCondition   || false;
        this.chronicConditions = this.medicalHx.chronicConditions ?
        this.cloneList(this.medicalHx.chronicConditions as string []) : [];
        this.chronicConditionsDiagnosisDatas = this.medicalHx.chronicConditionDiagnosisDates || [];
        this.takingMedications = this.medicalHx.takingMedications || false;
        this.currentMeds = this.medicalHx.currentMedications ?
        this.cloneList(this.medicalHx.currentMedications as string []) : [];
        this.hadSurgeries = this.medicalHx.hadSurgeries || false;
        this.surgeries = this.medicalHx.surgeries ?
        this.cloneList(this.medicalHx.surgeries as string []) : [];
        this.beenHospitalized = this.medicalHx.beenHospitalized || false;
        this.hospitalizations = this.medicalHx.hospitalizations ?
        this.cloneList(this.medicalHx.hospitalizations as string []) : [];
        this.hadIssuesWithBloodDraw = this.medicalHx.hadIssuesWithBloodDraw || false;
        this.bloodDrawIssues = this.medicalHx.bloodDrawIssues ?
        this.cloneList(this.medicalHx.bloodDrawIssues as string []) : [];
        this.$validator.reset();
      } else {
        this.hasCronicalConditions = false;
        this.chronicConditions = [];
        this.chronicConditionsDiagnosisDatas = [];
        this.takingMedications = false;
        this.currentMeds = [''];
        this.hadSurgeries = false;
        this.surgeries = [''];
        this.beenHospitalized = false;
        this.hospitalizations = [];
        this.hadIssuesWithBloodDraw = false;
        this.bloodDrawIssues = [];
        this.$validator.reset();
      }
  }

  public cancel() {
    this.$router.back();
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: IPatientMedicalHx = {
            hasChronicCondition: this.hasCronicalConditions,
            chronicConditions: this.chronicConditions,
            chronicConditionDiagnosisDates: this.chronicConditionsDiagnosisDatas,
            takingMedications: this.takingMedications,
            currentMedications: this.currentMeds,
            hadSurgeries: this.hadSurgeries,
            surgeries: this.surgeries,
            beenHospitalized: this.beenHospitalized,
            hospitalizations: this.hospitalizations,
            hadIssuesWithBloodDraw: this.hadIssuesWithBloodDraw,
            bloodDrawIssues: this.bloodDrawIssues,
        };
        this.$emit('patient-input::medical-hx', patient);
    }
  }

}
