












































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { IPatientContactInfo } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientContactInfo extends AppComponent {
  @Prop({ required: false }) public contactInfo: IPatientContactInfo;
  public valid = false;

  public phoneNumber: string = '';
  public streetAddress: string = '';
  public city: string = '';
  public state: any = '';
  public zipCode: string = '';
  public email: string = '';


  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('contactInfo')
  public onContactInfoChanged(newValue: IPatientContactInfo) {
    this.reset();
  }

  public reset() {
    this.phoneNumber = this.contactInfo ? this.contactInfo.phoneNumber || '' : '';
    this.streetAddress = this.contactInfo ? this.contactInfo.streetAddress || '' : '';
    this.city = this.contactInfo ? this.contactInfo.city || '' : '';
    this.state = this.contactInfo ? this.contactInfo.state || '' : '';
    this.zipCode = this.contactInfo ? this.contactInfo.zip || '' : '';
    this.email = this.contactInfo ? this.contactInfo.email || '' : '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: IPatientContactInfo = {
            email: this.email,
            phoneNumber: this.phoneNumber,
            streetAddress: this.streetAddress,
            city: this.city,
            state: this.state,
            zip: this.zipCode,
        };
        this.$emit('patient-input::contact-info', patient);
    }
  }

}
