



























































































































import {Component, Prop} from 'vue-property-decorator';
import {
  dispatchCreatePatient,
  dispatchCreatePatientBehavior,
  dispatchCreatePatientContact,
  dispatchCreatePatientCovid,
  dispatchCreatePatientFemale,
  dispatchCreatePatientMedicalHx,
  dispatchCreatePatientMotivational,
  dispatchCreatePriorPregnancies,
  dispatchGetPatientById,
  dispatchUpdatePatient,
  dispatchUpdatePatientBehavior,
  dispatchUpdatePatientContact,
  dispatchUpdatePatientCovid,
  dispatchUpdatePatientFemale,
  dispatchUpdatePatientMedicalHx,
  dispatchUpdatePatientMotivational,
  dispatchUpdatePriorPregnancies,
} from '@/store/crud/actions';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import PatientCustomerService from '@/components/PatientCustomerService.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {
  ICovidInfo,
  ICreateFemaleReproductiveHealth,
  IFemaleReproductiveHealth,
  IMotivationalInfo,
  IPatient,
  IPatientBehaviorInfo,
  IPatientContactInfo,
  IPatientCreate,
  IPatientMedicalHx,
  IPatientUpdate,
  IPriorPregnancies,
} from '@/interfaces/patients';
import PatientGeneralInfo from '@/views/main/admin/patients/patient-pages/PatientGeneralInfo.vue';
import PatientContactInfo from '@/views/main/admin/patients/patient-pages/PatientContactInfo.vue';
import PatientMedicalHx from '@/views/main/admin/patients/patient-pages/PatientMedicalHx.vue';
import PatientCovidInfo from '@/views/main/admin/patients/patient-pages/PatientCovidInfo.vue';
import PatientBehaviorInfo from '@/views/main/admin/patients/patient-pages/PatientBehaviorInfo.vue';
import PatientMotivationalInfo from '@/views/main/admin/patients/patient-pages/PatientMotivationalInfo.vue';
import PatientFemaleHealthInfo from '@/views/main/admin/patients/patient-pages/PatientFemaleHealthInfo.vue';

@Component({
  components: {
    DataPicker,
    ListComponent,
    PatientGeneralInfo,
    PatientContactInfo,
    PatientMedicalHx,
    PatientCovidInfo,
    PatientBehaviorInfo,
    PatientMotivationalInfo,
    PatientFemaleHealthInfo,
    PatientCustomerService,
  },
})
export default class Patient extends AppComponent {
  @Prop({required: false}) public id: string | undefined;
  public valid = false;
  public fetchingPatientData = false;
  public step = '2';

  public patientProfile: IPatient = {};

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    await this.fecthData();
    this.reset();
    this.checkNextStep();
  }

  public reset() {
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async fecthData() {
    if (this.id) {
      this.fetchingPatientData = true;
      this.patientProfile = await dispatchGetPatientById(this.$store, this.id);
      this.fetchingPatientData = false;
      return Promise.resolve();
    }
  }

  public async refreshPatientData() {
    // refresh the userData
    if (this.patientProfile && this.patientProfile.id) {
      this.patientProfile = await dispatchGetPatientById(this.$store, this.patientProfile?.id);
      this.checkNextStep();
    } else {
      // weird case, lets return to adminPatients
      this.$router.push('/admin/patients');
    }
  }

  public showFemaleStep() {
    return (
      this.patientProfile &&
      this.patientProfile.id &&
      this.patientProfile.biologicalSex &&
      this.patientProfile.biologicalSex.toUpperCase() === 'FEMALE'
    );
  }

  public checkNextStep() {
    if (this.patientProfile && Object.keys(this.patientProfile).length > 0) {
      if (!this.patientProfile.patientContactInfo || !this.patientProfile.patientContactInfo.id) {
        this.step = '2';
      } else if (!this.patientProfile.medicalHx || !this.patientProfile.medicalHx.id) {
        this.step = '3';
      } else if (
        (this.showFemaleStep() && // if it is female and no info or if no female and covid info
          (!this.patientProfile.femaleReproductiveHealth ||
            !this.patientProfile.femaleReproductiveHealth.id)) ||
        (!this.showFemaleStep() && (!this.patientProfile.covid || !this.patientProfile.covid!.id))
      ) {
        this.step = '4';
      } else if (
        !this.patientProfile.patientBehaviorInfo ||
        !this.patientProfile.patientBehaviorInfo.id
      ) {
        this.step = '5';
      } else if (!this.patientProfile.motivational || !this.patientProfile.motivational.id) {
        this.step = '6';
      } else if (this.showFemaleStep()) {
        if (!this.patientProfile.covid || !this.patientProfile.covid.id) {
          this.step = '7';
        } else {
          this.step = '1';
        }
      } else {
        this.step = '1';
      }
    } else {
      this.step = '1';
    }
  }

  public isCreation(): boolean {
    return this.patientProfile && !this.patientProfile.id;
  }

  public getPatientName(): string {
    if (this.patientProfile && this.patientProfile.id) {
      if (this.patientProfile.fname && this.patientProfile.lname) {
        return `${this.patientProfile.fname} ${this.patientProfile.lname}`;
      } else if (this.patientProfile.fname) {
        return this.patientProfile.fname;
      } else if (this.patientProfile.lname) {
        return this.patientProfile.lname;
      }
    }
    return 'Create Patient';
  }

  public checkIfStepIsEditable(): boolean {
    if (this.patientProfile && this.patientProfile.id) {
      return true;
    } else {
      return false;
    }
  }

  public async createPriorPregnancy(priorPregnanciesInfo: IPriorPregnancies, id) {
    const patientPriorPregnancyInfo: IPriorPregnancies = {} as IPriorPregnancies;
    if (priorPregnanciesInfo && Object.keys(priorPregnanciesInfo).length > 0) {
      patientPriorPregnancyInfo.femaleReproductiveHealthId = id;

      if (this.hasValue(priorPregnanciesInfo.count)) {
        patientPriorPregnancyInfo.count = priorPregnanciesInfo.count;
      }
      if (this.hasValue(priorPregnanciesInfo.numberOfChildren)) {
        patientPriorPregnancyInfo.numberOfChildren = priorPregnanciesInfo.numberOfChildren;
      }
      if (this.hasValue(priorPregnanciesInfo.numberOfMiscarriages)) {
        patientPriorPregnancyInfo.numberOfMiscarriages = priorPregnanciesInfo.numberOfMiscarriages;
      }
      if (this.hasValue(priorPregnanciesInfo.numberOfAbortions)) {
        patientPriorPregnancyInfo.numberOfAbortions = priorPregnanciesInfo.numberOfAbortions;
      }
    }

    const result = await dispatchCreatePriorPregnancies(this.$store, patientPriorPregnancyInfo);
    if (result && this.patientProfile.femaleReproductiveHealth) {
      this.patientProfile.femaleReproductiveHealth.priorPregnancy = result;
      return Promise.resolve(result);
    }

    return Promise.resolve(false);
  }

  public async updatePriorPregnancies(priorPregnanciesInfo: IPriorPregnancies) {
    const patientPriorPregnancyInfo: IPriorPregnancies = {} as IPriorPregnancies;
    if (
      this.patientProfile.femaleReproductiveHealth &&
      this.patientProfile.femaleReproductiveHealth.priorPregnancy
    ) {
      const currentPriorPregnancy = this.patientProfile.femaleReproductiveHealth.priorPregnancy;
      this.setFieldIfChanges(
        patientPriorPregnancyInfo,
        'count',
        priorPregnanciesInfo.count,
        currentPriorPregnancy.count,
      );
      this.setFieldIfChanges(
        patientPriorPregnancyInfo,
        'numberOfChildren',
        priorPregnanciesInfo.numberOfChildren,
        currentPriorPregnancy.numberOfChildren,
      );
      this.setFieldIfChanges(
        patientPriorPregnancyInfo,
        'numberOfMiscarriages',
        priorPregnanciesInfo.numberOfMiscarriages,
        currentPriorPregnancy.numberOfMiscarriages,
      );
      this.setFieldIfChanges(
        patientPriorPregnancyInfo,
        'numberOfAbortions',
        priorPregnanciesInfo.numberOfAbortions,
        currentPriorPregnancy.numberOfAbortions,
      );
      if (Object.keys(patientPriorPregnancyInfo).length > 0) {
        this.patientProfile.femaleReproductiveHealth.priorPregnancy = await dispatchUpdatePriorPregnancies(
          this.$store,
          {
            id: this.patientProfile.femaleReproductiveHealth.priorPregnancy.id!,
            priorPregnancies: patientPriorPregnancyInfo,
          },
        );
      }
    } else {
      // we need to create one
      if (this.patientProfile.femaleReproductiveHealth) {
        const result = await this.createPriorPregnancy(
          priorPregnanciesInfo,
          this.patientProfile.femaleReproductiveHealth!.id,
        );
        if (result) {
          this.patientProfile.femaleReproductiveHealth.priorPregnancy = result;
        }
      }
    }
  }

  public async submitFemaleHealth(patientFemaleData: ICreateFemaleReproductiveHealth) {
    if (patientFemaleData && this.patientProfile && this.patientProfile.id) {
      const femaleHealthInfo = patientFemaleData.femaleHealth;
      const priorPregnanciesInfo = patientFemaleData.priorPregnancies;

      // data structures to send to the server
      const patientFemaleHealthInfo: IFemaleReproductiveHealth = {} as IFemaleReproductiveHealth;
      patientFemaleHealthInfo.patientId = this.patientProfile.id;

      if (
        this.patientProfile.femaleReproductiveHealth &&
        this.patientProfile.femaleReproductiveHealth.id
      ) {
        delete patientFemaleHealthInfo.patientId;
        // update
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'currentlyOnBirthControl',
          femaleHealthInfo.currentlyOnBirthControl,
          this.patientProfile.femaleReproductiveHealth?.currentlyOnBirthControl,
        );
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'birthControlType',
          femaleHealthInfo.birthControlType,
          this.patientProfile.femaleReproductiveHealth?.birthControlType,
        );
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'timeOnBirthControl',
          femaleHealthInfo.timeOnBirthControl,
          this.patientProfile.femaleReproductiveHealth?.timeOnBirthControl,
        );

        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'dateOfLastPeriod',
          femaleHealthInfo.dateOfLastPeriod,
          this.patientProfile.femaleReproductiveHealth?.dateOfLastPeriod,
        );
        // this one is a Date type
        if (!femaleHealthInfo.dateOfLastPeriod || femaleHealthInfo.dateOfLastPeriod.length === 0) {
          delete patientFemaleHealthInfo.dateOfLastPeriod;
        }

        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'currentlyPregnant',
          femaleHealthInfo.currentlyPregnant,
          this.patientProfile.femaleReproductiveHealth?.currentlyPregnant,
        );
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'tryingToConceive',
          femaleHealthInfo.tryingToConceive,
          this.patientProfile.femaleReproductiveHealth?.tryingToConceive,
        );
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'currentMenstrualStatus',
          femaleHealthInfo.currentMenstrualStatus,
          this.patientProfile.femaleReproductiveHealth?.currentMenstrualStatus,
        );
        this.setFieldIfChanges(
          patientFemaleHealthInfo,
          'priorPregnancies',
          femaleHealthInfo.priorPregnancies,
          this.patientProfile.femaleReproductiveHealth?.priorPregnancies,
        );

        await this.updatePriorPregnancies(priorPregnanciesInfo as IPriorPregnancies);

        if (patientFemaleHealthInfo && Object.keys(patientFemaleHealthInfo).length > 0) {
          const result = await dispatchUpdatePatientFemale(this.$store, {
            id: this.patientProfile.femaleReproductiveHealth.id,
            femaleInfo: patientFemaleHealthInfo,
          });
          if (result) {
            this.patientProfile.femaleReproductiveHealth = result;
          }
        }
      } else {
        // create
        if (this.hasValue(femaleHealthInfo.currentlyOnBirthControl)) {
          patientFemaleHealthInfo.currentlyOnBirthControl =
            femaleHealthInfo.currentlyOnBirthControl;
        }
        if (this.hasValue(femaleHealthInfo.birthControlType)) {
          patientFemaleHealthInfo.birthControlType = femaleHealthInfo.birthControlType;
        }
        if (this.hasValue(femaleHealthInfo.timeOnBirthControl)) {
          patientFemaleHealthInfo.timeOnBirthControl = femaleHealthInfo.timeOnBirthControl;
        }
        if (this.hasValue(femaleHealthInfo.dateOfLastPeriod)) {
          patientFemaleHealthInfo.dateOfLastPeriod = femaleHealthInfo.dateOfLastPeriod;
        }
        if (this.hasValue(femaleHealthInfo.currentlyPregnant)) {
          patientFemaleHealthInfo.currentlyPregnant = femaleHealthInfo.currentlyPregnant;
        }
        if (this.hasValue(femaleHealthInfo.tryingToConceive)) {
          patientFemaleHealthInfo.tryingToConceive = femaleHealthInfo.tryingToConceive;
        }
        if (this.hasValue(femaleHealthInfo.currentMenstrualStatus)) {
          patientFemaleHealthInfo.currentMenstrualStatus = femaleHealthInfo.currentMenstrualStatus;
        }
        if (this.hasValue(femaleHealthInfo.priorPregnancies)) {
          patientFemaleHealthInfo.priorPregnancies = femaleHealthInfo.priorPregnancies;
        }

        const femaleHealthResult = await dispatchCreatePatientFemale(
          this.$store,
          patientFemaleHealthInfo,
        );
        if (femaleHealthResult) {
          const priorPregnanciesResult = await this.createPriorPregnancy(
            priorPregnanciesInfo || ({} as IPriorPregnancies),
            femaleHealthResult.id,
          );
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitMotivational(motivationalInfo: IMotivationalInfo) {
    if (motivationalInfo && this.patientProfile && this.patientProfile.id) {
      const patientMotivationalInfo: IMotivationalInfo = {} as IMotivationalInfo;
      patientMotivationalInfo.patientId = this.patientProfile.id;

      if (this.patientProfile.motivational && this.patientProfile.motivational.id) {
        delete patientMotivationalInfo.patientId;
        // update
        this.setFieldIfChanges(
          patientMotivationalInfo,
          'tenYearHealthGoals',
          motivationalInfo.tenYearHealthGoals,
          this.patientProfile.motivational?.tenYearHealthGoals,
        );
        this.setFieldIfChanges(
          patientMotivationalInfo,
          'purposeForJoiningFunction',
          motivationalInfo.purposeForJoiningFunction,
          this.patientProfile.motivational?.purposeForJoiningFunction,
        );
        this.setFieldIfChanges(
          patientMotivationalInfo,
          'howDidTheyHearAboutFunction',
          motivationalInfo.howDidTheyHearAboutFunction,
          this.patientProfile.motivational?.howDidTheyHearAboutFunction,
        );
        this.setFieldIfChanges(
          patientMotivationalInfo,
          'commentsBeforeBooking',
          motivationalInfo.commentsBeforeBooking,
          this.patientProfile.motivational?.commentsBeforeBooking,
        );

        if (patientMotivationalInfo && Object.keys(patientMotivationalInfo).length > 0) {
          const result = await dispatchUpdatePatientMotivational(this.$store, {
            id: this.patientProfile.motivational.id,
            motivationalInfo: patientMotivationalInfo,
          });
          if (result) {
            this.patientProfile.motivational = result;
          }
        }
      } else {
        // create
        if (this.hasValue(motivationalInfo.tenYearHealthGoals)) {
          patientMotivationalInfo.tenYearHealthGoals = motivationalInfo.tenYearHealthGoals;
        }
        if (this.hasValue(motivationalInfo.purposeForJoiningFunction)) {
          patientMotivationalInfo.purposeForJoiningFunction =
            motivationalInfo.purposeForJoiningFunction;
        }
        if (this.hasValue(motivationalInfo.howDidTheyHearAboutFunction)) {
          patientMotivationalInfo.howDidTheyHearAboutFunction =
            motivationalInfo.howDidTheyHearAboutFunction;
        }
        if (this.hasValue(motivationalInfo.commentsBeforeBooking)) {
          patientMotivationalInfo.commentsBeforeBooking = motivationalInfo.commentsBeforeBooking;
        }

        const result = await dispatchCreatePatientMotivational(
          this.$store,
          patientMotivationalInfo,
        );

        if (result) {
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitBehaviorInfo(behaviorInfo: IPatientBehaviorInfo) {
    if (behaviorInfo && this.patientProfile && this.patientProfile.id) {
      const patientBehaviorInfo: IPatientBehaviorInfo = {} as IPatientBehaviorInfo;
      patientBehaviorInfo.patientId = this.patientProfile.id;

      if (this.patientProfile.patientBehaviorInfo && this.patientProfile.patientBehaviorInfo.id) {
        delete patientBehaviorInfo.patientId;
        // update
        if (
          this.isListDifferent(
            behaviorInfo.diet || [],
            this.patientProfile.patientBehaviorInfo.diet || [],
          )
        ) {
          patientBehaviorInfo.diet = behaviorInfo.diet;
        }
        this.setFieldIfChanges(
          patientBehaviorInfo,
          'physicallyActive',
          behaviorInfo.physicallyActive,
          this.patientProfile.patientBehaviorInfo?.physicallyActive,
        );
        this.setFieldIfChanges(
          patientBehaviorInfo,
          'smoker',
          behaviorInfo.smoker,
          this.patientProfile.patientBehaviorInfo?.smoker,
        );
        this.setFieldIfChanges(
          patientBehaviorInfo,
          'smokingFrequency',
          behaviorInfo.smokingFrequency,
          this.patientProfile.patientBehaviorInfo?.smokingFrequency,
        );
        this.setFieldIfChanges(
          patientBehaviorInfo,
          'alcohol',
          behaviorInfo.alcohol,
          this.patientProfile.patientBehaviorInfo?.alcohol,
        );
        this.setFieldIfChanges(
          patientBehaviorInfo,
          'alcoholFrequency',
          behaviorInfo.alcoholFrequency,
          this.patientProfile.patientBehaviorInfo?.alcoholFrequency,
        );

        if (patientBehaviorInfo && Object.keys(patientBehaviorInfo).length > 0) {
          const result = await dispatchUpdatePatientBehavior(this.$store, {
            id: this.patientProfile.patientBehaviorInfo.id,
            behaviorInfo: patientBehaviorInfo,
          });
          if (result) {
            this.patientProfile.patientBehaviorInfo = result;
            this.checkNextStep();
          }
        }
      } else {
        // create
        if (this.hasValue(behaviorInfo.diet)) {
          patientBehaviorInfo.diet = behaviorInfo.diet;
        }
        if (this.hasValue(behaviorInfo.physicallyActive)) {
          patientBehaviorInfo.physicallyActive = behaviorInfo.physicallyActive;
        }
        if (this.hasValue(behaviorInfo.smoker)) {
          patientBehaviorInfo.smoker = behaviorInfo.smoker;
        }
        if (this.hasValue(behaviorInfo.smokingFrequency)) {
          patientBehaviorInfo.smokingFrequency = behaviorInfo.smokingFrequency;
        }
        if (this.hasValue(behaviorInfo.alcohol)) {
          patientBehaviorInfo.alcohol = behaviorInfo.alcohol;
        }
        if (this.hasValue(behaviorInfo.alcoholFrequency)) {
          patientBehaviorInfo.alcoholFrequency = behaviorInfo.alcoholFrequency;
        }

        const result = await dispatchCreatePatientBehavior(this.$store, patientBehaviorInfo);
        if (result) {
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitCovid(covid: ICovidInfo) {
    if (covid && this.patientProfile && this.patientProfile.id) {
      const patientCovidInfo: ICovidInfo = {} as ICovidInfo;
      patientCovidInfo.patientId = this.patientProfile.id;

      if (this.patientProfile.covid && this.patientProfile.covid.id) {
        delete patientCovidInfo.patientId;
        // update
        this.setFieldIfChanges(
          patientCovidInfo,
          'hadCovid',
          covid.hadCovid,
          this.patientProfile.covid?.hadCovid,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'covidDate',
          covid.covidDate,
          this.patientProfile.covid?.covidDate,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'covidSeverity',
          covid.covidSeverity,
          this.patientProfile.covid?.covidSeverity,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'hasCovidResidualSymptoms',
          covid.hasCovidResidualSymptoms,
          this.patientProfile.covid?.hasCovidResidualSymptoms,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'covidResidualSymptoms',
          covid.covidResidualSymptoms,
          this.patientProfile.covid?.covidResidualSymptoms,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'vaccinatedForCovid',
          covid.vaccinatedForCovid,
          this.patientProfile.covid?.vaccinatedForCovid,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'covidVaccineName',
          covid.covidVaccineName,
          this.patientProfile.covid?.covidVaccineName,
        );
        this.setFieldIfChanges(
          patientCovidInfo,
          'covidVaccineDateLastDosage',
          covid.covidVaccineDateLastDosage,
          this.patientProfile.covid?.covidVaccineDateLastDosage,
        );

        if (patientCovidInfo && Object.keys(patientCovidInfo).length > 0) {
          const result = await dispatchUpdatePatientCovid(this.$store, {
            id: this.patientProfile.covid.id,
            covidInfo: patientCovidInfo,
          });
          if (result) {
            this.patientProfile.covid = result;
            this.checkNextStep();
          }
        }
      } else {
        // create
        if (this.hasValue(covid.hadCovid)) {
          patientCovidInfo.hadCovid = covid.hadCovid;
        }
        if (this.hasValue(covid.covidDate)) {
          patientCovidInfo.covidDate = covid.covidDate;
        }
        if (this.hasValue(covid.covidSeverity)) {
          patientCovidInfo.covidSeverity = covid.covidSeverity;
        }
        if (this.hasValue(covid.hasCovidResidualSymptoms)) {
          patientCovidInfo.hasCovidResidualSymptoms = covid.hasCovidResidualSymptoms;
        }
        if (this.hasValue(covid.covidResidualSymptoms)) {
          patientCovidInfo.covidResidualSymptoms = covid.covidResidualSymptoms;
        }
        if (this.hasValue(covid.vaccinatedForCovid)) {
          patientCovidInfo.vaccinatedForCovid = covid.vaccinatedForCovid;
        }
        if (this.hasValue(covid.covidVaccineName)) {
          patientCovidInfo.covidVaccineName = covid.covidVaccineName;
        }
        if (this.hasValue(covid.covidVaccineDateLastDosage)) {
          patientCovidInfo.covidVaccineDateLastDosage = covid.covidVaccineDateLastDosage;
        }

        const result = await dispatchCreatePatientCovid(this.$store, patientCovidInfo);
        if (result) {
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitMedicalHistory(medicalHx: IPatientMedicalHx) {
    if (medicalHx && this.patientProfile && this.patientProfile.id) {
      const patientMedicalHx: IPatientMedicalHx = {} as IPatientMedicalHx;
      patientMedicalHx.patientId = this.patientProfile.id;

      if (this.patientProfile.medicalHx && this.patientProfile.medicalHx.id) {
        delete patientMedicalHx.patientId;
        // update case
        this.setFieldIfChanges(
          patientMedicalHx,
          'hasChronicCondition',
          medicalHx.hasChronicCondition,
          this.patientProfile.medicalHx?.hasChronicCondition,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'chronicConditions',
          medicalHx.chronicConditions,
          this.patientProfile.medicalHx?.chronicConditions,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'chronicConditionDiagnosisDates',
          medicalHx.chronicConditionDiagnosisDates,
          this.patientProfile.medicalHx?.chronicConditionDiagnosisDates,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'takingMedications',
          medicalHx.takingMedications,
          this.patientProfile.medicalHx?.takingMedications,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'currentMedications',
          medicalHx.currentMedications,
          this.patientProfile.medicalHx?.currentMedications,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'hadSurgeries',
          medicalHx.hadSurgeries,
          this.patientProfile.medicalHx?.hadSurgeries,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'surgeries',
          medicalHx.surgeries,
          this.patientProfile.medicalHx?.surgeries,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'beenHospitalized',
          medicalHx.beenHospitalized,
          this.patientProfile.medicalHx?.beenHospitalized,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'hospitalizations',
          medicalHx.hospitalizations,
          this.patientProfile.medicalHx?.hospitalizations,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'hadIssuesWithBloodDraw',
          medicalHx.hadIssuesWithBloodDraw,
          this.patientProfile.medicalHx?.hadIssuesWithBloodDraw,
        );
        this.setFieldIfChanges(
          patientMedicalHx,
          'bloodDrawIssues',
          medicalHx.bloodDrawIssues,
          this.patientProfile.medicalHx?.bloodDrawIssues,
        );

        if (patientMedicalHx && Object.keys(patientMedicalHx).length > 0) {
          const result = await dispatchUpdatePatientMedicalHx(this.$store, {
            id: this.patientProfile.medicalHx.id,
            medicalHx: patientMedicalHx,
          });
          if (result) {
            this.patientProfile.medicalHx = result;
            this.checkNextStep();
          }
        }
      } else {
        // create case
        if (this.hasValue(medicalHx.hasChronicCondition)) {
          patientMedicalHx.hasChronicCondition = medicalHx.hasChronicCondition;
        }
        if (this.hasValue(medicalHx.chronicConditions)) {
          patientMedicalHx.chronicConditions = medicalHx.chronicConditions;
        }
        if (this.hasValue(medicalHx.chronicConditionDiagnosisDates)) {
          patientMedicalHx.chronicConditionDiagnosisDates =
            medicalHx.chronicConditionDiagnosisDates;
        }
        if (this.hasValue(medicalHx.takingMedications)) {
          patientMedicalHx.takingMedications = medicalHx.takingMedications;
        }
        if (this.hasValue(medicalHx.currentMedications)) {
          patientMedicalHx.currentMedications = medicalHx.currentMedications;
        }
        if (this.hasValue(medicalHx.hadSurgeries)) {
          patientMedicalHx.hadSurgeries = medicalHx.hadSurgeries;
        }
        if (this.hasValue(medicalHx.surgeries)) {
          patientMedicalHx.surgeries = medicalHx.surgeries;
        }
        if (this.hasValue(medicalHx.beenHospitalized)) {
          patientMedicalHx.beenHospitalized = medicalHx.beenHospitalized;
        }
        if (this.hasValue(medicalHx.hospitalizations)) {
          patientMedicalHx.hospitalizations = medicalHx.hospitalizations;
        }
        if (this.hasValue(medicalHx.hadIssuesWithBloodDraw)) {
          patientMedicalHx.hadIssuesWithBloodDraw = medicalHx.hadIssuesWithBloodDraw;
        }
        if (this.hasValue(medicalHx.bloodDrawIssues)) {
          patientMedicalHx.bloodDrawIssues = medicalHx.bloodDrawIssues;
        }

        const result = await dispatchCreatePatientMedicalHx(this.$store, patientMedicalHx);
        if (result) {
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitContactInfo(contactInfo: IPatientContactInfo) {
    if (contactInfo && this.patientProfile && this.patientProfile.id) {
      const patientContactInfo: IPatientContactInfo = {} as IPatientContactInfo;
      patientContactInfo.patientId = this.patientProfile.id;

      if (this.patientProfile.patientContactInfo && this.patientProfile.patientContactInfo.id) {
        delete patientContactInfo.patientId;
        // Edit case
        this.setFieldIfChanges(
          patientContactInfo,
          'email',
          contactInfo.email,
          this.patientProfile.patientContactInfo
            ? this.patientProfile.patientContactInfo.email
            : '',
        );
        this.setFieldIfChanges(
          patientContactInfo,
          'phoneNumber',
          contactInfo.phoneNumber,
          this.patientProfile.patientContactInfo
            ? this.patientProfile.patientContactInfo.phoneNumber
            : '',
        );
        this.setFieldIfChanges(
          patientContactInfo,
          'streetAddress',
          contactInfo.streetAddress,
          this.patientProfile.patientContactInfo
            ? this.patientProfile.patientContactInfo.streetAddress
            : '',
        );
        this.setFieldIfChanges(
          patientContactInfo,
          'state',
          contactInfo.state,
          this.patientProfile.patientContactInfo
            ? this.patientProfile.patientContactInfo.state
            : '',
        );
        this.setFieldIfChanges(
          patientContactInfo,
          'zip',
          contactInfo.zip,
          this.patientProfile.patientContactInfo ? this.patientProfile.patientContactInfo.zip : '',
        );
        this.setFieldIfChanges(
          patientContactInfo,
          'city',
          contactInfo.city,
          this.patientProfile.patientContactInfo ? this.patientProfile.patientContactInfo.city : '',
        );

        if (patientContactInfo && Object.keys(patientContactInfo).length > 0) {
          const result = await dispatchUpdatePatientContact(this.$store, {
            id: this.patientProfile.patientContactInfo.id,
            contactInfo: patientContactInfo,
          });
          if (result) {
            this.patientProfile.patientContactInfo = result;
            this.checkNextStep();
          } else {
            this.toast('Error updating contact info', true);
          }
        }
      } else {
        // Create case

        if (this.hasValue(contactInfo.email)) {
          patientContactInfo.email = contactInfo.email;
        }
        if (this.hasValue(contactInfo.phoneNumber)) {
          patientContactInfo.phoneNumber = contactInfo.phoneNumber;
        }
        if (this.hasValue(contactInfo.streetAddress)) {
          patientContactInfo.streetAddress = contactInfo.streetAddress;
        }
        if (this.hasValue(contactInfo.city)) {
          patientContactInfo.city = contactInfo.city;
        }
        if (this.hasValue(contactInfo.state)) {
          patientContactInfo.state = contactInfo.state;
        }
        if (this.hasValue(contactInfo.zip)) {
          patientContactInfo.zip = contactInfo.zip;
        }

        const result = await dispatchCreatePatientContact(this.$store, patientContactInfo);
        if (result) {
          this.refreshPatientData();
        }
      }
    }
  }

  public async submitGeneralInfo(patientInfo: IPatient) {
    if (patientInfo) {
      const protoPatient: any = {};

      if (this.patientProfile && this.patientProfile.id) {
        // edit case
        this.setFieldIfChanges(
          protoPatient,
          'patientFirebaseId',
          patientInfo.patientFirebaseId,
          this.patientProfile.patientFirebaseId,
        );
        this.setFieldIfChanges(protoPatient, 'fname', patientInfo.fname, this.patientProfile.fname);
        this.setFieldIfChanges(protoPatient, 'lname', patientInfo.lname, this.patientProfile.lname);

        /* Dont allow updating patient identifier data, this is a computed value  */
        // this.setFieldIfChanges(protoPatient, 'patientIdentifier',
        // patientInfo.patientIdentifier, this.patientProfile.patientIdentifier);

        this.setFieldIfChanges(protoPatient, 'ssn', patientInfo.ssn, this.patientProfile.ssn);
        this.setFieldIfChanges(protoPatient, 'dob', patientInfo.dob, this.patientProfile.dob);
        this.setFieldIfChanges(
          protoPatient,
          'preferredName',
          patientInfo.preferredName,
          this.patientProfile.preferredName,
        );
        this.setFieldIfChanges(
          protoPatient,
          'biologicalSex',
          patientInfo.biologicalSex,
          this.patientProfile.biologicalSex,
        );
        this.setFieldIfChanges(
          protoPatient,
          'pronouns',
          patientInfo.pronouns,
          this.patientProfile.pronouns,
        );
        this.setFieldIfChanges(
          protoPatient,
          'ethnicOrigin',
          patientInfo.ethnicOrigin,
          this.patientProfile.ethnicOrigin,
        );
        this.setFieldIfChanges(
          protoPatient,
          'shirtSize',
          patientInfo.shirtSize,
          this.patientProfile.shirtSize,
        );

        const patientUpdate: IPatientUpdate = protoPatient as IPatientUpdate;
        if (!this.isEmpty(patientUpdate)) {
          const result = await dispatchUpdatePatient(this.$store, {
            patientId: this.patientProfile.id,
            patient: patientUpdate,
          });
          if (result) {
            this.refreshPatientData();
          }
        }
      } else {
        // create case
        const createPatient: IPatientCreate = protoPatient;

        if (this.hasValue(patientInfo.fname)) {
          createPatient.fname = patientInfo.fname;
        }
        if (this.hasValue(patientInfo.lname)) {
          createPatient.lname = patientInfo.lname;
        }
        if (this.hasValue(patientInfo.patientFirebaseId)) {
          createPatient.patientFirebaseId = patientInfo.patientFirebaseId;
        }
        if (this.hasValue(patientInfo.preferredName)) {
          createPatient.preferredName = patientInfo.preferredName;
        }
        if (this.hasValue(patientInfo.biologicalSex)) {
          createPatient.biologicalSex = patientInfo.biologicalSex;
        }
        if (this.hasValue(patientInfo.dob)) {
          createPatient.dob = patientInfo.dob;
        }
        if (this.hasValue(patientInfo.pronouns)) {
          createPatient.pronouns = patientInfo.pronouns;
        }
        if (this.hasValue(patientInfo.ethnicOrigin)) {
          createPatient.ethnicOrigin = patientInfo.ethnicOrigin;
        }

        /*
            for any reason, doing this block with async / await doesn't work
            the code generated from typescript compilation is wrong.
           */
        dispatchCreatePatient(this.$store, createPatient).then((result) => {
          if (result) {
            this.$router.push(`/main/admin/patient/${result.id}`).catch();
            this.patientProfile = result;
            this.checkNextStep();
          } else {
            this.$toast.error('Failed to create patient, please try again later');
          }
        });
      }
    }
  }
}
