




















































import { Component, Prop, Watch } from 'vue-property-decorator';
import DataPicker from '@/components/DataPicker.vue';
import ListComponent from '@/components/ListComponent.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { ICovidInfo } from '@/interfaces/patients';

@Component({ components: { DataPicker, ListComponent } })
export default class PatientCovidInfo extends AppComponent {
  @Prop({ required: false }) public covidData: ICovidInfo;
  public valid = false;

  public hadCovid: boolean = false;
  public covidDate: string = '';
  public covidSeverity: string = '';
  public hasCovidResidualSymptoms: boolean = false;
  public covidResidualSymptoms: string[] = [];
  public vaccinatedForCovid: boolean = false;
  public vaccineLastDosageDate: string = '';
  public covidVaccineName: string = '';

  public mounted() {
    this.initScreen();
  }

  public async initScreen() {
    this.setAppLoading(true);
    this.reset();
    this.setAppLoading(false);
  }

  @Watch('covidData')
  public onContactInfoChanged(newValue: ICovidInfo) {
    this.reset();
  }

  public reset() {
      if (this.covidData && this.covidData.id) {
        this.hadCovid = this.covidData.hadCovid || false;
        this.covidDate = this.covidData.covidDate || '';
        this.covidSeverity = this.covidData.covidSeverity || '';
        this.hasCovidResidualSymptoms = this.covidData.hasCovidResidualSymptoms || false;
        this.covidResidualSymptoms = this.covidData.covidResidualSymptoms || [];
        this.vaccinatedForCovid = this.covidData.vaccinatedForCovid || false;
        this.vaccineLastDosageDate = this.covidData.covidVaccineDateLastDosage || '';
        this.covidVaccineName = this.covidData.covidVaccineName || '';

        this.$validator.reset();
      } else {
        this.hadCovid = false;
        this.covidDate = '';
        this.covidSeverity = '';
        this.hasCovidResidualSymptoms = false;
        this.covidResidualSymptoms = [];
        this.vaccinatedForCovid = false;
        this.vaccineLastDosageDate = '';
        this.covidVaccineName = '';
        this.$validator.reset();
      }
  }

  public cancel() {
    this.$router.back();
  }

  public setCovidDate(newDate) {
    this.covidDate = newDate;
  }

  public setLastVaccineDate(newDate) {
    this.vaccineLastDosageDate = newDate;
  }


  public async submit() {
    if (await this.$validator.validateAll()) {
        const patient: ICovidInfo = {
            hadCovid: this.hadCovid,
            covidDate: this.covidDate,
            covidSeverity: this.covidSeverity,
            hasCovidResidualSymptoms: this.hasCovidResidualSymptoms,
            covidResidualSymptoms: this.covidResidualSymptoms,
            vaccinatedForCovid: this.vaccinatedForCovid,
            covidVaccineDateLastDosage: this.vaccineLastDosageDate,
            covidVaccineName: this.covidVaccineName,
        };
        this.$emit('patient-input::covid', patient);
    }
  }

}
